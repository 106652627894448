<template>
  <static-fullscreen-card>
    <template v-slot:header></template>
    <template v-slot:actions>
      <v-btn color="primary" @click="refreshTable()"><btn-title icon="fas fa-sync-alt">Обновить</btn-title> </v-btn>
      <v-btn color="primary" @click="createNew()" title="Создать"><btn-title icon="far fa-plus-square">Создать</btn-title></v-btn>
    </template>

    <portal to="v-main">
      <s-edit-form
        v-if="showEditDialog"
        v-model="showEditDialog"
        :m="configH.m"
        :configForm="{ head: configH.head, title: configH.title, api: configH.api }"
        :id="configH.id"
        :opt="configH.opt"
        :initData="configH.initData || {}"
      />
    </portal>
    <a-table-f-api ref="table" :api="url" :model="getModelList(m)" :useQuery="false" :defaults="defaults" @click="onClickRow($event)" />
  </static-fullscreen-card>
</template>

<script>
import { genModel } from "@/components/mixings";
export default {
  mixins: [genModel],
  components: {
    ViewItem: () => import("./views/offerView"),
  },
  data() {
    return {
      idEdit: 0,
      showEditDialog: false,
      m: this.$store.getters["config/get"].models.billing,
      defaults: {
        sort: { key: "id", order: "DESC" },
      },
      showItem: false,
      configH: null,
      idItemShow: 0,
    };
  },
  computed: {
    url() {
      return this.m.url;
    },
  },
  created() {
    this.$root.title = "Billing";
  },
  watch: {
    showEditDialog(v) {
      if (!v) {
        this.$refs.table.updateData();
      }
    },
  },
  methods: {
    itemShowClose(d) {
      this.showItem = !true;

      this.loading = true;
      this.loading = !true;
      this.offerShow = null;
    },
    refreshTable() {
      this.$refs.table.updateData();
    },
    onClickRow(d) {
      const config = {};
      config.type = "edit";
      this.configH = null;
      config.head = this.m.config[config.type] || {};
      config.title = config.head.title;
      config.api = this.m.api;
      config.id = d.row.id;
      config.opt = "delete";
      config.m = this.m;
      this.configH = config;
      this.showEditDialog = true;
    },
    createNew() {
      const config = {};
      config.type = "create";
      this.configH = null;
      config.head = this.m.config[config.type] || {};
      config.title = config.head.title;
      config.api = this.m.api;
      config.id = null;
      config.opt = "";
      config.m = this.m;
      this.configH = config;
      this.showEditDialog = true;
    },
  },
};
</script>
